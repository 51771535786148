import  React, { useState, useEffect, useRef } from 'react';
import { Container } from './media.styles';

const Media = ({ url, hero, alt, width, height, className}) => {
    const [loaded, setLoaded] = useState(false);
    const image = useRef(null);

    useEffect(() => {
        if (image && image.current.complete) {
            setLoaded(true);
        }
    }, [image]);
    
    const onLoad = () => {
        setLoaded(true);
    };

    return (
        <Container loaded={loaded} hero={hero} className={className}>
            <img width={width} height={height} ref={image} src={url} alt={alt} onLoad={onLoad}/>
        </Container>
    );
};

export default Media;