import React, { useState } from 'react';
import { Link } from 'gatsby-link';
import { Container, ToggleButton, MenuItem } from './navigation.styles';

const Navigation = ({ toggleMenuCb }) => {
    const [toggled, setToggled] = useState(false);

    const setToggledFn = (val) => {
        if (toggleMenuCb) {
            toggleMenuCb(val);
        }

        setToggled(val);
    };

    return (
        <>
            <ToggleButton
                toggled={toggled}
                role="button"
                onClick={() => setToggledFn(!toggled)}
                aria-controls="mainNavigation"
                aria-expanded={toggled}
                aria-label={`${toggled ? 'Dölj' : 'Visa'} huvudmenyn`}
            />
            <Container toggled={toggled} id="mainNavigation">
                <MenuItem toggled={toggled}>
                    <Link
                        to="/"
                        onClick={() => setToggledFn(false)}
                    >
                        Start
                    </Link>
                </MenuItem>
                <MenuItem toggled={toggled}>
                    <Link
                        to="/for-boende"
                        onClick={() => setToggledFn(false)}
                    >
                        För boende
                    </Link>
                </MenuItem>
                <MenuItem toggled={toggled}>
                    <Link
                        to="/vardprogram"
                        onClick={() => setToggledFn(false)}
                    >
                        Vårdprogram
                    </Link>
                </MenuItem>
                <MenuItem toggled={toggled}>
                    <Link
                        to="/foreningen"
                        onClick={() => setToggledFn(false)}
                    >
                        Föreningen
                    </Link>
                </MenuItem>
                <MenuItem toggled={toggled}>
                    <Link
                        to="/historia"
                        onClick={() => setToggledFn(false)}
                    >
                        Historia
                    </Link>
                </MenuItem>
                <MenuItem toggled={toggled}>
                    <Link
                        to="/in-english"
                        onClick={() => setToggledFn(false)}
                    >
                        In English
                    </Link>
                </MenuItem>
            </Container>
        </>
    );
};

export default Navigation;
