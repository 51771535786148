import React from 'react';
import Media from './media';
import Navigation from './navigation';
import logoUrl from '../images/sparrisbacken.png';
import { Container, Inner, LogoLink } from './header.styles';

const Header = ({ toggleMenuCb }) => (
    <Container>
        <Inner>
            <LogoLink to="/">
                <>
                    <Media url={logoUrl} alt="BRF Sjöträdgårdens logotyp" />
                    <span aria-label="Gå till startsidan" />
                </>
            </LogoLink>
            <Navigation toggleMenuCb={toggleMenuCb} />
        </Inner>
    </Container>
);

export default Header;
