import styled, { css } from 'styled-components';

export const Container = styled.div`
    background-color: transparent;
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: opacity 200ms linear;

    img {
        transition: opacity 200ms linear;
        opacity: 0;
        margin: 0;
    }

    ${(props) =>
        props.loaded &&
        css`
            opacity: 1;

            img {
                opacity: 1;
            }
        `}

    ${(props) =>
        props.hero &&
        css`
            height: 82vh;
            max-height: 500px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    height: auto;
                }
            }

            @media screen and (max-width: 600px) {
                height: 42vh;
            }
        `}
`;
