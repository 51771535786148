import styled, { css } from 'styled-components';

export const Container = styled.div`
    margin: 90px auto 30px auto;
    text-align: center;

    @media all and (max-width: 650px) {
        transition: transform ease-in-out 0.5s;

        ${(props) =>
            props.toggledMenu &&
            css`
                transform: translate3d(0, 240px, 0);
            `}
    }
`;

export const Logo = styled.div`
    display: block;
    margin: auto;
    width: 100px;
    height: 100px;
    margin-bottom: 20px;

    img {
        width: 100%;
    }
`;

export const Contact = styled.div`
    display: inline-block;
    font-size: 12px;
    line-height: 1.2;

    p {
        margin: 0;
        opacity: 0.7;
    }
`;

export const Address = styled.p`
    padding-top: 1em;
`;