import styled, { css } from 'styled-components';

export const Content = styled.div`
    min-height: calc(100vh - 290px);
    clear: both;

    @media all and (max-width: 650px) {
        transition: transform ease-in-out 0.5s;
        ${(props) =>
            props.toggledMenu &&
            css`
                transform: translate3d(0, 280px, 0);
            `}
    }
`;
