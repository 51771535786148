import React, { useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { Helmet } from "react-helmet";
import { Content } from './content.styles';
import { GlobalStyle } from './global.styles';

const Template = ({ children }) => {
    const [toggledMenu, setToggledMenu] = useState(false);

    return (
        <>
            <GlobalStyle/>
            <Helmet>
                <title>Sparrisbacken - BRF Sjöträdgården</title>
                <meta name="description" content="BRF Sjöträdgården är en trivsam förening på Sparrisbacken i Hässelby strand." />
                <html lang="sv" />
                <link rel="preload" href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Alegreya:wght@400;700&display=block" as="style"/>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Alegreya:wght@400;700&display=block"/>
            </Helmet>
            <Header toggleMenuCb={(val) => setToggledMenu(val)}/>
            <Content toggledMenu={toggledMenu}>
                {children}
            </Content>
            <Footer toggledMenu={toggledMenu}/>
        </>
    );
    
};

export default Template
