import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby-link';
import { COLOR_WHITE } from '../layouts/global.styles';

export const Container = styled.div`
    background-color: ${COLOR_WHITE};
    position: -webkit-sticky; /* needed for ios*/
    position: sticky;
    top: 0;
    z-index: 9;

    @media all and (max-width: 650px) {
        position: inherit;
    }
`;

export const Inner = styled.div`
    color: #fff;
    height: 90px;
    padding: 10px 20px;
    margin: auto;
    background-color: transparent;
    position: relative;
    width: 840px;
    max-width: 100%;

    @media all and (max-width: 530px) {
        padding: 10px 10px;
    }
`;

const fadeIn = keyframes`
    0% {
        transform: translateY(-10px);
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;

export const LogoLink = styled(Link)`
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: auto;
    position: absolute;
    z-index: 3;
    top: 10px;
    right: 0;
    left: 0;
    margin: -5px 0 0 0;
    transition: transform 100ms ease-in-out;
    transform: translateZ(0);

    &:hover {
        transform: scale(1.05);

        &:before {
            border: none;
        }
    }

    &:before {
        border: none;
    }

    img {
        width: 100%;
        animation: ${fadeIn} 800ms ease-in-out;
    }

    @media all and (max-width: 650px) {
        margin: -5px auto auto auto;
    }
`;
