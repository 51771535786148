import styled, { css } from 'styled-components';

export const Container = styled.ul`
    list-style: none;
    margin: 0 0 0 120px;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    animation: mFadeIn 200ms ease-in-out;
    align-items: center;
    height: 100%;
    font-size: 15px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    @media all and (max-width: 600px) {
        margin: 0 0 0 110px;
        transition: transform ease-in-out 0.5s;
    }

    @media all and (max-width: 650px) {
        will-change: transform;
        position: absolute;
        left: 33px;
        top: 37px;
        max-width: 100%;
        z-index: 2;
        font-size: 20px;
        margin: 0;
        display: block;
        z-index: 0;
        margin: 0;
        pointer-events: none;
        transition: transform ease-in-out 0.3s;

        ${(props) =>
            props.toggled &&
            css`
                pointer-events: auto;
                transform: translate(0px, 60px);
            `}
    }
`;

export const ToggleButton = styled.a`
    border: none;
    cursor: pointer;
    position: absolute;
    display: none;
    width: 40px;
    height: 40px;
    margin: 15px 10px;
    background-color: transparent;
    z-index: 2;
    border: none;
    cursor: pointer;
    border-radius: 100%;
    transition: 0.51s;

    &:before,
    &:after {
        position: absolute;
        content: '';
        top: 0;
        margin: auto;
        right: 0;
        bottom: 0;
        left: 0;
        width: 70%;
        height: 2px;
        background-color: #000;
        border-radius: 5px;
        transition: 0.5s;
    }

    &:before {
        transform: translateY(-50%) rotate(45deg) scale(0);
    }

    &:after {
        transform: translateY(50%) rotate(-45deg) scale(0);
    }

    ${(props) =>
        props.toggled &&
        css`
            &:before {
                transform: translateY(-50%) rotate(45deg) scale(1);
            }

            &:after {
                transform: translateY(-50%) rotate(-45deg) scale(1);
            }
        `}

    @media all and (max-width: 650px) {
        display: inline-block;
    }
`;

export const MenuItem = styled.li`
    margin: 0 15px 0 0;
    padding: 0;
    overflow: auto;
    color: black;

    a {
        color: black;
        font-weight: normal;
        text-decoration: none;
        border-bottom: 1px solid transparent;
        transition: border-color 120ms ease-in-out,
            background-color 120ms ease-in-out;
        padding: 3px 0;

        &:hover {
            border-color: black;
        }
    }

    @media all and (max-width: 650px) {
        position: relative;
        display: inline-block;
        float: left;
        clear: both;
        color: transparent;
        font-size: 14px;
        letter-spacing: -6.2px;
        height: 7px;
        line-height: 7px;
        text-transform: uppercase;
        white-space: nowrap;
        transform: scaleY(0.2);
        transition: 0.5s, opacity 1s, background-color 0.5s 0.2s;
        background-color: black;
        transform-origin: top left;

        a {
            padding: 10px 0;

            @nest .menu--toggled & {
                background-color: transparent;
            }
        }

        &:nth-child(n + 4) {
            transition-delay: 0.05s;
            transform: scale(0);
        }

        ${(props) =>
            props.toggled &&
            css`
                transform: scaleY(1);
                letter-spacing: 0;
                height: 40px;
                line-height: 40px;
                background-color: transparent;
                transition: 0.5s, opacity 1s, background-color 0s 0s;

                &:nth-child(1) {
                    transition-delay: 0.15s;
                }

                &:nth-child(2) {
                    transition-delay: 0.1s;
                }

                &:nth-child(3) {
                    transition-delay: 0.05s;
                }

                &:nth-child(n + 4) {
                    transform: scale(1);
                    transition-delay: 0s;
                }
            `}
    }
`;
