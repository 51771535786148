import React from 'react';
import Media from './media';
import logoUrl from '../images/sparrisbacken.png';
import { Container, Logo, Contact, Address } from './footer.styles';

const Footer = ({ toggledMenu }) => {
    return (
        <Container toggledMenu={toggledMenu}>
            <Logo>
                <Media url={logoUrl} alt="BRF Sjöträdgårdens logotyp"/>
            </Logo>
            <Contact>
                <p>Bostadsrättsföreningen Sjöträdgården</p>
                <p>Organisationsnummer 702001-9423</p>
                <Address>Brf Sjöträdgården</Address>
                <p>NABO 6954, FE 617</p>
                <p>107 76 Stockholm</p>
            </Contact>
        </Container>
    );
};

export default Footer;